<template>
  <div class="cal-event-detail template-3">
    <g-background>
      <page-body>
        <section class="section-1">
          <header class="mb-2">
            <h1>{{ meetingTitle }}</h1>
            <h5>{{ eventName }}</h5>
          </header>
          <div class="d-flex row details">
            <div class="col">
              <h2 class="h-style-1 mb-2">{{ meetingFrequency }}</h2>
              <div class="detail">
                <iconContent :obj="meetDate" />
                <iconContent v-if="hasEventLocation" :obj="meetLocation" />
              </div>
            </div>
            <div class="col">
              <h2 class="h-style-1 mb-2">
                {{ translations.tcCoordinator }}
              </h2>
              <div class="detail">
                <p v-if="!!coordinator">
                  <strong>{{ coordinator }}</strong>
                </p>
                <p v-else>
                  <i>No {{ translations.tcCoordinator }}</i>
                </p>
                <p>
                  <template v-if="!!coordinator">
                    <i>{{ coordinator_phone }}</i>
                    <br />
                    <a href="mailto:"
                      ><i>{{ coordinator_email }}</i></a
                    >
                  </template>
                  <br />{{ translations.tcMemberType }}:
                  <span class="gideon">{{ memberType }}</span>
                </p>
              </div>
            </div>
          </div>
          <section class="form-buttons">
            <b-button v-if="translations.components" variant="primary" @click="addMeeting">
              {{ translations.tcAddToMyCalendar }}
            </b-button>
            <b-button v-if="accessible_edit" variant="primary" class="ml-4" @click="meetingEdit">
              {{ translations.tcEdit }}
            </b-button>
            <b-button v-if="accessible_delete" variant="primary" class="ml-4" @click="meetingSoftDelete">
              {{ translations.tcDelete }}
            </b-button>
            <b-button variant="primary" class="ml-4" @click="goback">
              {{ translations.tcCancel }}
            </b-button>
          </section>
        </section>
      </page-body>
    </g-background>
    <v-dialog />
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState, mapGetters } from 'vuex'
import AddMeetingToEmail from '@/components/AddMeetingToEmail.vue'
import { CALENDAR_API_CONFIG } from '@/config/calendar.api.config'
import calendarService from '../../../services/calendarService'
import gBackground from '@/components/gBackground.vue'
import iconContent from '@/components/iconContent.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import { Loader } from '@/mixins/Loader'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'
import ExtractEventTitles from '@/services/utils/ExtractEventTitles.js'

export default {
  name: 'cal-event-detail',
  mixins: [calendarMixin, Loader, translationMixin, securityMixin],
  data() {
    return {
      translations: {},
      user_accessible_edit: false,
      user_accessible_delete: false,
      state_user_accessible_edit: false,
      state_user_accessible_delete: false,
      view_calendar_event_controls: {
        calendar_edit_event_button: '77656905-18c2-436f-b722-2650cd199a20',
        calendar_delete_event_button: 'f7854ee5-6797-430e-baa4-daa3d5ad2913',
        state_calendar_edit_event_button: '31fa3072-0fd6-4aab-bf89-63ea1f75e752',
        state_calendar_delete_event_button: '9ba0ac7e-337b-4dda-a699-6a3dad091090'
      },
      mgt_key: null,
      mtr_key: null,
      id: null,
      meeting_obj: null,
      show: false,
      meetDate: {
        img: {
          src: 'svgs/iCalendar.svg',
          width: 22,
          height: 22
        },
        content: ''
      },
      meetLocation: {
        img: {
          src: 'svgs/iMapPoint.svg',
          width: 18,
          height: 29
        },
        content: ''
      },
      meetingTitle: null,
      eventName: null,
      meetingFreq: null,
      meetingFrequency: null,
      coordinator: null,
      memberType: null,
      coordinator_phone: null,
      coordinator_email: null
    }
  },
  methods: {
    ...mapActions({
      clearSelected: 'eventCalendar/clearSelected',
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      setLoadingStatus: 'menu/setLoadingStatus',
      setUser: 'user/setUser'
    }),
    async goback() {
      await this.clearSelected()
      this.$router.push({ path: `../calendar-month` })
    },
    meetingSoftDelete() {
      let button = []
      let title = this.translations.tcAreYouSure
      if (!!this.meeting_obj.reccurrencetype && this.meeting_obj.reccurrencetype.vmrt_trn_txt !== 'Once') {
        title = this.translations.tcRecurringMeetingEdit
        button = [
          {
            title: this.translations.tcDeleteRecurrence,
            handler: () => {
              this.setLoadingStatus(true)
              calendarService
                .DeleteByRecurrence(this.meeting_obj.reccurrencetype.mtr_key, 'newuser') //TODO: get user name from store or sesssion
                .then(res => {
                  if (res.status === 200) {
                    this.$swal({
                      icon: 'success',
                      text: this.translations.tcDeletionSuccessful,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      confirmButtonText: this.translations.tcOk || 'Ok'
                    }).then(result => {
                      if (result) {
                        this.$router.push({ path: `/calendar/calendar-month` })
                      }
                    })
                    this.$parent.$emit('close')
                    this.$modal.hide('dialog')
                  }
                })
                .catch(err => {})
                .finally(() => {
                  this.setLoadingStatus(false)
                })
            }
          }
        ]
      }
      this.$modal.show('dialog', {
        title: this.translations.tcAlert,
        text: title,
        buttons: [
          {
            title: this.translations.tcNo, //MeetingDeleteByRecurrence
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.translations.tcDeleteMeeting, // Button title
            default: true, // Will be triggered by default if 'Enter' pressed.
            handler: () => {
              this.setLoadingStatus(true)
              calendarService
                .meetingSoftDelete(this.meeting_obj.id, 'newuser') //TODO: get user name from store or sesssion
                .then(res => {
                  if (res.status === 200) {
                    this.$swal({
                      icon: 'success',
                      text: this.translations.tcDeletionSuccessful,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      confirmButtonText: this.translations.tcOk || 'Ok'
                    }).then(result => {
                      if (result) {
                        this.$router.push({ path: `/calendar/calendar-month` })
                      }
                    })
                  }
                })
                .catch(err => {
                  console.error('error: ', err)
                })
                .finally(() => {
                  this.setLoadingStatus(false)
                })
            } // Button click handler
          },
          ...button
        ]
      })
    },
    meetingEdit() {
      let button = []
      let title = this.translations.tcAreYouSure
      if (!this.meeting_obj.reccurrencetype) {
        this.$router.push({ path: `/calendar/camp-calendar/edit-event/once/${this.mtr_key}/${this.id}` })
      } else {
        if (!!this.meeting_obj.reccurrencetype && this.meeting_obj.reccurrencetype.vmrt_trn_txt !== 'Once') {
          ;(title = this.translations.tcRecurringMeetingEdit),
            (button = [
              {
                title: this.translations.tcEditRecurrence,
                handler: () => {
                  this.$modal.hide('dialog')
                  this.$parent.$emit('close')
                  this.$router.push({
                    path: `/calendar/camp-calendar/edit-event/multiple/${this.mtr_key}`
                  })
                }
              }
            ])
        }
        this.$modal.show('dialog', {
          title: this.translations.tcAlert,
          text: title,
          buttons: [
            {
              title: this.translations.tcNo,
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: this.translations.tcEditThisMeeting, // Button title
              default: true, // Will be triggered by default if 'Enter' pressed.
              handler: () => {
                this.$modal.hide('dialog')
                this.$parent.$emit('close')
                this.$router.push({
                  path: `/calendar/camp-calendar/edit-event/single/${this.mtr_key}/${this.id}`
                })
              } // Button click handler
            },
            ...button
          ]
        })
      }
    },
    addMeeting() {
      let cmp = {
        template: `<add-meeting-to-email :meeting='objSelected' :downloadUrl='downloadUrl' :i18n='i18n' />`,
        components: {
          'add-meeting-to-email': AddMeetingToEmail
        },
        props: ['objSelected', 'downloadUrl', 'i18n']
      }

      let data = {
        objSelected: this.meeting_obj,
        downloadUrl: CALENDAR_API_CONFIG.DownloadICS(this.meeting_obj.id),
        i18n: this.translations.components
      }

      let style = {
        height: 'auto',
        scrollable: true,
        draggable: true,
        transition: 'overlay-fade'
      }

      this.$modal.show(cmp, data, style)
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      meetingSelected: 'eventCalendar/meetingSelected',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin'
    }),
    hasEventLocation() {
      return (
        this.meetingSelected &&
        this.meetingSelected.hasOwnProperty('location') &&
        !Object.values(this.meetingSelected.location).every(itm => itm === null)
      )
    },
    eventCategoryName() {
      return this.meetingSelected.hasOwnProperty('mtg_category_name') && this.meetingSelected.mtg_category_name
    },
    eventDistributionName() {
      return this.meetingSelected.hasOwnProperty('mtg_distribution_name') && this.meetingSelected.mtg_distribution_name
    },
    eventTypeName() {
      return this.meetingSelected.hasOwnProperty('mtg_event_name') && this.meetingSelected.mtg_event_name
    },
    accessible_delete() {
      return (
        !!this.meetingSelected &&
        ((this.meetingSelected.scope === 'State' && this.state_user_accessible_delete) ||
          (this.meetingSelected.scope === 'Camp' && this.user_accessible_delete))
      )
    },
    accessible_edit() {
      return (
        !!this.meetingSelected &&
        ((this.meetingSelected.scope === 'State' && this.state_user_accessible_edit) ||
          (this.meetingSelected.scope === 'Camp' && this.user_accessible_edit))
      )
    }
  },
  components: {
    'add-meeting-to-email': AddMeetingToEmail,
    gBackground: gBackground,
    iconContent: iconContent,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader
  },
  async mounted() {
    await this.getControlPermissionsFromList(Object.values(this.view_calendar_event_controls)).then(() => {
      this.user_accessible_edit = this.determineAccessibility(
        this.view_calendar_event_controls.calendar_edit_event_button
      )
      this.user_accessible_delete = this.determineAccessibility(
        this.view_calendar_event_controls.calendar_delete_event_button
      )
      this.state_user_accessible_edit = this.determineAccessibility(
        this.view_calendar_event_controls.state_calendar_edit_event_button
      )
      this.state_user_accessible_delete = this.determineAccessibility(
        this.view_calendar_event_controls.state_calendar_delete_event_button
      )
    })
  },
  async created() {
    try {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getViewTranslations('buttons', 'date-time'),
        await this.getComponentTranslations('add-meeting-to-email'),
        await this.getComponentTranslations('common.calendar-event-type', 'common.calendar-event-type-state'),
        ExtractEventTitles(this.prefCulture)
      ]).then(results => {
        this.stripReadableText(results[3])

        const newCalendarEventTypesTranslations = results[4] // Get the returned translations

        if (newCalendarEventTypesTranslations) {
          this.translations.newCalendarEventTypes = newCalendarEventTypesTranslations // Set the translations
        }

        this.translations.common['event-types'] = {
          // merge event types together
          ...this.translations.common['calendar-event-type'],
          ...this.translations.common['calendar-event-type-state']
        }
        const translatedText = {
          ...results[2],
          common: {
            ...this.translations.common
          }
        }
        this.$set(this.translations, 'components', translatedText)
      })
    } catch (error) {
      console.error(`There was an error retrieving translations for module ${this.$options.name}: ${error}`)
      throw error
    } finally {
      this.meeting_obj = this.meetingSelected
      if (!this.meetingSelected.meetingtitle) {
        this.$router.push({ path: `../../calendar-month` })
      }

      const distributionPlacementKey = this.meetingSelected.mtg_distribution_placement_key
      this.mtr_key = this.meeting_obj.mtrkey
      this.id = this.meeting_obj.id
      const titleGuid = this.meeting_obj.meetingtype ? this.meeting_obj.meetingtype.toLowerCase() : ''
      this.meetingTitle = this.translations.common['event-types'].hasOwnProperty(titleGuid)
        ? this.translations.common['event-types'][titleGuid]
        : distributionPlacementKey !== '00000000-0000-0000-0000-000000000000' || null
        ? this.translations.newCalendarEventTypes[distributionPlacementKey]
        : this.meeting_obj.title
      this.eventName = this.meeting_obj.eventname
      this.meetingFreq = !!this.meeting_obj.reccurrencetype ? this.meeting_obj.reccurrencetype.vmrt_trn_txt : ''
      ;(this.coordinator = this.meeting_obj.vip_formal_name),
        (this.coordinator_phone = this.meeting_obj.vip_phone),
        (this.coordinator_email = this.meeting_obj.vip_email),
        (this.memberType = this.translations[this.convertValForTranslation(this.meeting_obj.vmpt_trn_txt)])
      this.meetLocation.content =
        `<p><strong>${this.meeting_obj.hostname}</strong>` +
        (!!this.meeting_obj.location.org_name ? `<br/>${this.meeting_obj.location.org_name}` : '') +
        (!!this.meeting_obj.location.adr_line1 ? `<br/>${this.meeting_obj.location.adr_line1}` : '') +
        (!!this.meeting_obj.location.adr_line2 ? `<br/>${this.meeting_obj.location.adr_line2}` : '') +
        (!!this.meeting_obj.location.adr_line3 ? `<br/>${this.meeting_obj.location.adr_line3}` : '')
      this.meetLocation.content += !!this.meeting_obj.location.adr_city
        ? `<br/>${this.meeting_obj.location.adr_city}, `
        : ' '
      this.meetLocation.content += !!this.meeting_obj.location.adr_state
        ? `${this.meeting_obj.location.adr_state} `
        : ' '
      this.meetLocation.content += !!this.meeting_obj.location.adr_postal_code
        ? ` ${this.meeting_obj.location.adr_postal_code} `
        : ' '
      if (this.meeting_obj.locationVirtual && this.meeting_obj.locationVirtual.lvt_key) {
        this.meetLocation.content += `<br/>${this.meeting_obj.locationVirtual.lvt_name}`
        this.meetLocation.content += `<br/><a href="${this.meeting_obj.locationVirtual.lvt_url}" target="_blank">${this.meeting_obj.locationVirtual.lvt_url}</a>`
        this.meetLocation.content += this.meeting_obj.locationVirtual.lvt_note
          ? `<br/>${this.meeting_obj.locationVirtual.lvt_note}`
          : ''
      }
      this.meetLocation.content += ' </p>'
      const startDate = this.returnFormattedDate(this.meeting_obj.startdate)
      this.meetDate.content = `<p>${startDate} | ${this.meeting_obj.startendtime}<br/>
    ${this.meetingExpression(this.meeting_obj.reccurrencetype, this.translations)}</p>`
      this.meetingFrequency = this.meetingFrequencyText(this.meeting_obj.reccurrencetype, this.translations)
      await this.setLoadingStatus(false)
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.cal-event-detail {
  padding-top: 60px;

  .page-body {
    max-width: 1160px;
  }

  h1 {
    margin-bottom: 10px;
  }

  .col {
    width: auto;
    flex: 0 1 auto;
    margin-bottom: 30px;
    margin-right: 100px;

    @include breakpoint(sm) {
      width: 100%;
      margin-right: 0;

      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }

  .gideon {
    color: $primary-color;
  }

  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        width: 100%;
        margin-bottom: 15px;
        margin-left: 0 !important;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
